













































































import Vue from "vue";
import axios from "axios";
import { Token } from "@/types/token";
import { RouterFunctions } from "@/router/index";
import content from "@/content.json";

const totpLength = 6;

export default Vue.extend({
  name: "LoginComponent",

  data: () => ({
    page: content.pages.login,
    links: content.links,
    loginCredentials: {
      filledinUser: "",
      filledinPassword: "",
      filledinTOTP: "",
    },
    valid: true,
    error: "",
    headers: "Content-Type=application/x-www-form-urlencoded",
    emailRules: [
      (v: string) => !!v || "E-mailadres is verplicht",
      (v: string) => /.+@.+\..+/.test(v) || "Voer een geldig E-mailadres in",
    ],
    passwordRules: [(v: string) => !!v || "Wachtwoord is verplicht"],
    totpRules: [
      (v: string) =>
        !!v ||
        "De eenmalige code uit de Authenticator-app is verplicht, zorg dat deze op tijd wordt ingevoerd",
      (v: string) =>
        (v && v.length === totpLength) ||
        "De eenmalige code uit de Authenticator-app bestaat uit 6 cijfers ",
    ],
  }),
  methods: {
    Login() {
      this.error = "";
      axios
        .post(
          `${this.$store.state.APIurl}/user/login`,
          `username=${this.loginCredentials.filledinUser}&password=${this.loginCredentials.filledinPassword}${this.loginCredentials.filledinTOTP}`,
          { timeout: 2000 }
        )
        .then((response) => {
          const response_data: Token = response.data as unknown as Token;
          const access_token: string = response_data.access_token;
          sessionStorage.setItem("jwt", access_token);
          this.$store.commit("changeUserToken", access_token);
          RouterFunctions.runVerification(access_token);
          this.$router.push("/uploaden");
        })
        .catch((error) => {
          if (error.code == "ECONNABORTED") {
            this.error = content.common.timeout;
          } else {
            this.error = error.response.data.detail;
          }
        });
    },
  },
});
